import {Component, OnInit} from '@angular/core';
import {NgxPermissionsService} from 'ngx-permissions';
import {Router} from '@angular/router';
import {UserAuthService} from '../../@core/user-auth.service';
import {JWTService} from '../../@core/jwt.service';
import {take} from 'rxjs/operators';
import {FAC_MENU_ITEMS, OTHER_MENU_ITEMS} from '../../definitions/definitions';
import {SidebarComponent} from '../../portal/sidebar/sidebar.component';
import {User} from '../../models/user';

@Component({
    selector: 'app-landing-page',
    template: '',
})
export class LandingPageComponent implements OnInit {
    constructor(private userAuth: UserAuthService,
                private permissions: NgxPermissionsService,
                private jwt: JWTService,
                private router: Router) {
    }

    ngOnInit() {
        this.userAuth.user.pipe(take(1)).subscribe(u => {
            if (u) {
                this.routeDashboard(u);
            } else {
                this.router.navigate(['/login']);
            }
        });
    }

    landingPageOptions: {permission: string | string[]; path: string}[] = [
        {
            permission: 'HOMEPAGE_SUSPENDED',
            path: '/suspended',
        },
        {
            permission: ['PERMISSION_READ_CLINICAL_OVERSIGHT', 'PERMISSION_READ_CENSUS_OVERSIGHT'],
            path: '/whats-new',
        },
        {
            permission: 'PERMISSION_FOLLOW_PATIENTS',
            path: '/arsana-notes',
        },
    ];

    async routeDashboard(user: User) {
        // We set a dashboard url before checking the return url parameter, because we redirect to a slide panel url
        // After closing that the user will be presented with a blank page instead of anything useful.
        for (const landingPageOption of this.landingPageOptions) {
            if (await this.permissions.hasPermission(landingPageOption.permission)) {
                return await this.router.navigate([landingPageOption.path], {replaceUrl: true});
            }
        }

        [...FAC_MENU_ITEMS, ...OTHER_MENU_ITEMS].find(item => {
            let link: string;
            if (SidebarComponent.menuItemIsAvailable(item, user)) {
                link = item.children ?
                    item.children.find(childItem => SidebarComponent.menuItemIsAvailable(childItem, user))?.['link'] :
                    item.link;
            }
            if (link) this.router.navigate([link], {replaceUrl: true});
            return link;
        });
    }
}
